<template>
  <div ref="wrapper" class="flex flex-col space-y-2">
    <span>
      {{ label }}
    </span>
    <div
      v-tooltip="{
        content: value,
        onShow: () => textOverflows
      }"
      class="flex items-start space-x-2 w-full min-w-0"
    >
      <div ref="textContainer" class="min-w-0">
        <info-span-copy
          :text="value"
          :disabled="!clickToCopy"
          :data-cy="id"
          class="flex justify-start text-gray-700 w-full min-w-0"
        />

        <span
          ref="text"
          class="relative -z-5 whitespace-nowrap text-transparent"
        >
          {{ value || "-" }}
        </span>
      </div>
      <block-phone-actions
        v-if="id.includes('phone') && value && value !== '-'"
        class="mt-[10px]"
        :telephone-info="telephoneStatusInfo"
        :contact-name="phoneContactName"
        :is-business="isBusiness"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed } from "vue";
import InfoSpanCopy from "@/views/deals/components/InfoSpanCopy.vue";
import BlockPhoneActions from "@/views/deals/components/BlockPhoneActions.vue";
import { useDealsBase } from "@/hooks/deals";
import { formatUserFullName } from "@/helpers/formatting";
import { useDealTelephones } from "@/hooks/deals";
import type { IOwner } from "@/models/users";

const props = defineProps({
  /**
   * Allow user to click to copy field value
   */
  clickToCopy: {
    type: Boolean,
    default: false
  },
  /**
   * Identifier for copy function
   */
  id: {
    type: String,
    default: ""
  },
  /**
   * Required label for field
   */
  label: {
    type: String,
    default: ""
  },
  /**
   * Required value for field
   */
  value: {
    type: [String, Number],
    default: ""
  },

  isBusiness: {
    type: Boolean,
    default: false
  }
});

const { activeDeal } = useDealsBase();

const { getTelephoneStatusInfo } = useDealTelephones(activeDeal);

const wrapper = ref<HTMLElement | null>(null);
const textContainer = ref<HTMLElement | null>(null);
const text = ref<HTMLElement | null>(null);

const telephoneStatusInfo = computed(() =>
  getTelephoneStatusInfo(props.value?.toString(), props.isBusiness)
);

const textOverflows = computed(
  () =>
    !!textContainer.value &&
    !!text.value &&
    !wrapper.value?.classList?.contains("wrap-text") &&
    text.value.offsetWidth > textContainer.value.clientWidth
);

const isOwnerPhone = (owner: IOwner | null) =>
  !!owner?.all_telephones
    ?.map(({ number }) => number)
    .includes(props.value?.toString());

const phoneContactName = computed(() => {
  if (props.isBusiness) {
    return activeDeal.value?.business?.business_legal_name || "";
  }

  const isMainOwnerPhone = isOwnerPhone(activeDeal.value.personal_information);

  if (isMainOwnerPhone) {
    return formatUserFullName(activeDeal.value.personal_information);
  }

  const ownerWithPhone =
    activeDeal.value?.business?.other_owners?.find(isOwnerPhone) || null;

  return formatUserFullName(ownerWithPhone);
});
</script>
